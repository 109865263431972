require("turbolinks").start()
require("@rails/activestorage").start()

//Override the default confirm dialog
import Rails from '@rails/ujs'
Rails.start()
Rails.confirm = function(mes,element){
    element.setAttribute('data-anwsered', 'false')
    return true
}

document.addEventListener('confirm', (event)=>{
    let element = event.target
    if(element.getAttribute('data-anwsered') == 'true'){
        return true
    }
    pp(element)
    $.confirm({
        title: '请确认!',
        content: element.getAttribute('data-confirm'),
        closeIcon: true,
        buttons: {
            confirm: {
                text: '确定',
                btnClass: 'btn-red',
                action: function(){
                    element.setAttribute('data-anwsered', 'true')
                    // Rails.fire(element, 'rails.click')
                    element.click()
                }
            },
            cancel: {
                text: '取消',
                action: function(){
                }
            }
        }
    })
    event.preventDefault()
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
